import { request } from "../request";
import consumer from "./consumer"

connect();

async function connect() {
  const messagesPannel = document.querySelector('#messages-panel')
  if(!document.querySelector('#messages-panel')) return

  const dealerId = messagesPannel.attributes['data-dealer-id'].value
  consumer.subscriptions.create({ channel: "MessagesChannel", dealer_id: dealerId }, {
    connected() {},
    disconnected() {},
    async received() {
      const messagesPannel = document.querySelector('#messages-panel')
      const dealerId = messagesPannel.attributes['data-dealer-id'].value
      const url = `${dealerId}/messages`
      const options = { headers: { 'Content-Type': 'text/html', Accept: 'text/html' } }
      const response = await request(url, options);
      messagesPannel.innerHTML = await response.text()

      const messages = document.querySelectorAll('#messages-panel .panel-body.mesage-panel .tile')
      messages[messages.length - 1].scrollIntoView()
    }
  });
}
