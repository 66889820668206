import { getToken } from './token'

export function request(url, params = {}) {
  return fetch(url, {
    ...params,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': getToken(),
      ...params.headers
    }
  })
}

window.request = request
